import './App.css';
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { HostPage, WinnerPage, NotFound, DateSelectionPage, CancelPage } from './pages';
import { Header } from './components';
import { Grid } from '@material-ui/core';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ReactGA from 'react-ga';
import { AppProvider } from './contexts';
import FullStory from 'react-fullstory';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(66,142,117,1)',
    },
    secondary: {
      main: '#596f8c',
    },
  },
});

const useStyles = makeStyles({
  appContainer: {
    paddingTop: 70
  },
});

const ORG_ID = 'o-1J4TQA-na1';

const App = () => {
  const styles = useStyles();

  useEffect(() => {
    ReactGA.initialize('UA-171260520-1');
  }, []);

  return (
    <Router>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <Header />
          <FullStory org={ORG_ID} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid className={styles.appContainer}>
              <Switch>
                <Route path="/host/:hash" component={HostPage} />
                <Route path="/customer/:hash" component={WinnerPage} />
                <Route path="/dateselection/:hash" component={DateSelectionPage} />
                <Route path="/cancel/:hash" component={CancelPage} />
                <Route component={NotFound} />
              </Switch>
            </Grid>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
