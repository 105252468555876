import React, {useEffect, useState, useRef, useContext} from 'react';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Checkbox,
    Divider,
    Slide,
    Snackbar,
    Fade,
    Paper,
    Collapse,
    Hidden,
    Box
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import {Alert} from '@material-ui/lab';
import {DateSelector, DatePicker, PlacesTextField} from '../../components';
import {GuestSelector} from './GuestSelector';
import axios from 'axios';
import {API_URL} from '../../constants';
import {useParams, useHistory} from 'react-router';
import {format, addMinutes} from 'date-fns';
import ReactGA from 'react-ga';
import {makeStyles} from '@material-ui/core/styles';
import Map from './Map';
import golf from '../../images/golf.png';
import {AppContext} from '../../contexts';
import {convertDateToTimezone} from '../../helpers/Date';
import { FullStoryAPI } from 'react-fullstory';

const initialValues = {
    name: '',
    email: '',
    phone: '',
    termsOfService: false,
    notes: ''
};

const initialStatus = {
    errors: []
};

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /([0-9\s\-]{10,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
const schema = () => Yup.object().shape({
    name: Yup.string().required('* Required'),
    email: Yup.string().required('* Required').email('Invalid Email'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    termsOfService: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted.")
});

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function formatDate(date) {
    return format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    infoContainer: {
        position: 'relative',
        backgroundColor: theme.palette.secondary.main,
        color: 'rgba(250,250,250,1)',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            padding: 0,
            height: '50%',
            minHeight: 275,
            borderRadius: 0,
            '@media(max-height: 700px)': {
                overflowY: 'scroll'
            }
        }
    },
    golfIcon: {
        position: 'absolute',
        bottom: -60,
        right: -150,
        width: '80%',
        transform: 'rotate(-30deg)',
        zIndex: 0,
        opacity: '10%'
    },
    infoContainerGrid: {
        zIndex: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        }
    },
    formContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
            overflowY: 'scroll',
            height: '100%'
        }
    },
    form: {
        paddingTop: theme.spacing(4)
    },
    mapContainer: {
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
            padding: theme.spacing(2),
        }
    },
}));

const CountIcon = ({count}) => {
    return (
        <div
            style={{
                border: '3px solid white',
                width: 26,
                height: 26,
                borderRadius: '50%',
                textAlign: 'center',
            }}
        >
            <Typography variant="body1" style={{marginTop: 2}}><Box fontWeight="fontWeightBold">{count}</Box></Typography>
        </div>
    )
};

const CampaignInfo = ({campaign}) => {
    const styles = useStyles();

    return (
        <>
            <Paper className={styles.infoContainer}>
                <img className={styles.golfIcon} src={golf} />
                <Grid container className={styles.infoContainerGrid} spacing={1}>
                    {campaign.includesMeal == 1 && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item><CheckCircleIcon fontSize="large" /></Grid>
                                <Grid item><Typography variant="h6">Lunch / Dinner</Typography></Grid>
                            </Grid>
                        </Grid>
                    )}
                    {campaign.includesCaddies == 1 && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item><CheckCircleIcon fontSize="large" /></Grid>
                                <Grid item><Typography variant="h6">Includes Caddies</Typography></Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item><CountIcon count={campaign.roundsOfGolf} /></Grid>
                            <Grid item><Typography variant="h6">{campaign.roundsOfGolf > 1 ? 'Rounds of Golf' : 'Round of Golf'}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item><CountIcon count={campaign.numberOfAllowedGuests} /></Grid>
                            <Grid item><Typography variant="h6">{campaign.numberOfAllowedGuests == 1 ? 'Guest Allowed' : 'Guests Allowed'}</Typography></Grid>
                        </Grid>
                    </Grid>
                    {campaign.clubRules && campaign.clubRules != '' && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item><InfoRoundedIcon fontSize="large" /></Grid>
                                <Grid item>
                                    <Typography variant="h6">Club Rules</Typography>
                                    <Typography variant="body1">{campaign.clubRules}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {campaign.packageDescription && campaign.packageDescription != '' && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item><InfoRoundedIcon fontSize="large" /></Grid>
                                <Grid item>
                                    <Typography variant="h6">Event Description</Typography>
                                    <Typography variant="body1">{campaign.packageDescription}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <div className={styles.mapContainer}>
                {campaign.location ?
                    <Map location={campaign.location} />
                    : null}
            </div>
        </>
    );
};

export const WinnerCampaignForm = () => {
    const appCtx = useContext(AppContext);
    const styles = useStyles();
    const history = useHistory();
    const {hash} = useParams();
    const [dates, setDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState(undefined);
    const [selectedDateReason, setSelectedDateReason] = useState('');
    const [dateSelectorError, setDateSelectorError] = useState('');
    const [datePickerError, setDatePickerError] = useState('');
    const [serverError, setSeverError] = useState(undefined);
    const [succes, setSuccess] = useState(false);
    const [campaign, setCampaign] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [guests, setGuests] = useState([]);
    const [isMember, setIsMember] = useState(false);
    const [location, setLocation] = useState(undefined);
    const [locationError, setLocationError] = useState(undefined);
    const [identify, setIdentify] = useState(false)
    const closeSnackbar = () => setSeverError(undefined);
    const formik = useRef(null);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const getData = async () => {
            if (!campaign) {
                setLoading(true);
                try {
                    const result = await axios.get(`${API_URL}/campaign/${hash}`);
                    if (result.data && result.data.response) {

                        setCampaign(result.data.response);
                        setLoading(false);
                        console.log('you made it bub!')
                        if (result.data.response.npo) {
                            appCtx.setNpo(result.data.response.npo);
                        }

                        if (formik.current && result.data.response.winners && result.data.response.winners.length > 0) {
                            const winner = result.data.response.winners[0];
                            if (winner) {
                                formik.current?.setFieldValue('name', winner?.name);
                                formik.current?.setFieldValue('email', winner?.email);
                                formik.current?.setFieldValue('phone', winner?.phone);

                                if (!identify) {
                                    FullStoryAPI('identify', winner.id, {
                                        displayName: winner?.name,
                                        email: winner?.email
                                    });
                                    setIdentify(true)
                                }
                            }
                        }
                    } else {
                        history.push('/notfound');
                    }
                } catch (e) {
                    console.error('Request failed', e)
                    setLoading(false);
                    history.push('/notfound');
                }
            }
        };
        getData();
    }, [campaign]);

    const handleSubmit = async (values, formikHelpers) => {
        setDateSelectorError(undefined);
        setDatePickerError(undefined);

        let dateError = '';
        if (dates.length < 4) {
            dateError = 'Please add at least 4 dates';
        } else {
            for (const date of dates) {
                if (date.duration <= 1) {
                    dateError = 'Please add a duration to every date';
                }
            }
        }

        if (dateError.length > 0 && campaign.hosts?.length > 0 && (!campaign.hosts[0].dates || campaign.hosts[0].dates?.length <= 0)) {
            setDateSelectorError(dateError);
            return;
        } else if (campaign.hosts?.length > 0 && campaign.hosts[0].dates.length > 0) {
            const hasNone = (selectedDates ?? []).find(d => d.duration <= 0);
            if (!hasNone && (!selectedDates || (selectedDates && selectedDates.length < 2))) {
                setDatePickerError(`Please select your preferred and rain dates`);
                return;
            }
        }

        setLoading(true);

        try {
            const data = {
                name: values?.name,
                email: values.email,
                phone: values.phone,
                selectedDates: selectedDates,
                selectedDateReason: selectedDateReason,
                notes: values.notes,
                dates: dates.map(d => ({date: convertDateToTimezone(d.date, campaign.location?.timezone ?? 'America/New_York').format('YYYY-MM-DD HH:mm:ss'), duration: d.duration})),
                guests: guests,
                location: location
            };
            const result = await axios.post(`${API_URL}/winner/${hash}`, data);

            if (result.data.status === 'success') {
                setSuccess(true);
            }
        } catch (e) {
            setLoading(false);
            console.log(`failed to make submission with error: `, e);
            setSeverError(e.message);
        }

        setLoading(false);
    };

    if (loading) {
        return (
            <Grid container justify="center" alignItems="center" style={{minHeight: 300}}>
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    }

    const form = (
        <Formik
            initialValues={initialValues}
            initialStatus={initialStatus}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {formikVar => {
                formik.current = formikVar;

                return (
                    <form
                        onSubmit={e => formikVar.handleSubmit(e)}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field validateOnBlur validateOnChange name="name">
                                    {() => (
                                        <TextField
                                            required
                                            fullWidth
                                            variant="filled"
                                            name="name"
                                            label="Name"
                                            value={formikVar.values.name}
                                            onChange={formikVar.handleChange}
                                            onBlur={formikVar.handleBlur}
                                            error={Boolean(formikVar.errors.name && formikVar.touched.name)}
                                            helperText={formikVar.errors.name && formikVar.touched.name && String(formikVar.errors.name)}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field validateOnBlur validateOnChange name="email">
                                    {() => (
                                        <TextField
                                            required
                                            fullWidth
                                            variant="filled"
                                            name="email"
                                            label="Email"
                                            value={formikVar.values.email}
                                            onChange={formikVar.handleChange}
                                            onBlur={formikVar.handleBlur}
                                            error={Boolean(formikVar.errors.email && formikVar.touched.email)}
                                            helperText={formikVar.errors.email && formikVar.touched.email && String(formikVar.errors.email)}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field validateOnBlur validateOnChange name="phone">
                                    {() => (
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            name="phone"
                                            label="Phone"
                                            value={formikVar.values.phone}
                                            onChange={formikVar.handleChange}
                                            onBlur={formikVar.handleBlur}
                                            error={Boolean(formikVar.errors.phone && formikVar.touched.phone)}
                                            helperText={formikVar.errors.phone && formikVar.touched.phone && String(formikVar.errors.phone)}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {campaign.hosts?.length > 0 && campaign.hosts[0].dates && campaign.hosts[0].dates.length > 0 ? (
                                <Grid item xs={12}>
                                    <DatePicker
                                        headline="Host Availability"
                                        title={"Please select your preferred tee time and a rain date from the list below"}
                                        dates={campaign.hosts[0].dates}
                                        location={campaign.location}
                                        maxSelectedDates={2}
                                        updateSelectedDates={(d) => {
                                            setSelectedDates(d);
                                            setDatePickerError('');
                                            setSelectedDateReason('');
                                        }}
                                        selectedDates={selectedDates}
                                        updatedReason={setSelectedDateReason}
                                        reason={selectedDateReason}
                                        error={datePickerError}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <DateSelector
                                        headline="Tee Times"
                                        title="Please add some dates that would work for you (minimum 4). Dates should be selected in the same timezone as the course location."
                                        datesUpdated={(d) => setDates(d)}
                                        error={dateSelectorError}
                                    />
                                </Grid>
                            )}
                            {/* {campaign.hosts?.length > 0 && (!campaign.hosts[0].dates || campaign.hosts[0].dates?.length <= 0) && ( */}

                            {/* )} */}
                            {campaign.numberOfAllowedGuests > 0 && (
                                <Grid item xs={12}>
                                    <Grid item>
                                        <Typography variant="body1">Please ensure that we have all guest's names and email addresses (up to 3) listed below to avoid any delays in scheduling your round. These can be amended later should your guests change.</Typography>
                                    </Grid>
                                    <GuestSelector
                                        title={`You can add up to ${campaign.numberOfAllowedGuests} guests (including yourself)`}
                                        guestsUpdated={setGuests}
                                        max={campaign.numberOfAllowedGuests}
                                        winnerName={formikVar.values.name}
                                        winnerEmail={formikVar.values.email}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1">Are you a member of a golf club?</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            value={isMember}
                                            onChange={(e) => {
                                                setLocation(undefined);
                                                setIsMember(e.target.checked)
                                            }}
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={isMember} timeout="auto" unmountOnExit fullWidth>
                                    <PlacesTextField
                                        name="club"
                                        label="Name of Golf Course"
                                        selectedLocation={(l) => {
                                            setLocation(l);
                                            setLocationError(undefined);
                                        }}
                                        error={locationError}
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                                <Field name="notes">
                                    {() => (
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            rowsMax={6}
                                            variant="filled"
                                            name="notes"
                                            label="Notes"
                                            value={formikVar.values.notes}
                                            onChange={formikVar.handleChange}
                                            onBlur={formikVar.handleBlur}
                                            error={Boolean(formikVar.errors.notes && formikVar.touched.notes)}
                                            helperText={formikVar.errors.notes && formikVar.touched.notes && String(formikVar.errors.notes)}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1">Do you agree to the <a color="primary" target="_blank" href="https://www.memberforaday.com/terms-of-service">Terms of Service</a>?</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            required
                                            name="termsOfService"
                                            value={formikVar.values.termsOfService}
                                            onChange={formikVar.handleChange}
                                            onBlur={formikVar.handleBlur}
                                            error={Boolean(formikVar.errors.termsOfService && formikVar.touched.termsOfService)}
                                            helperText={formikVar.errors.termsOfService && formikVar.touched.termsOfService && String(formikVar.errors.termsOfService)}
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    SUBMIT
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );

    return (
        <>
            {succes && (
                <Fade in={succes}>
                    <Grid container justify="center" alignItems="center" style={{minHeight: 300}}>
                        <Grid item style={{textAlign: 'center'}}>
                            <CheckCircleIcon fontSize="large" color="primary" />
                            <Typography variant="h6">Success</Typography>
                        </Grid>
                    </Grid>
                </Fade>
            )}
            {!succes && campaign && (
                <Grid container spacing={0} className={styles.root}>
                    <Grid item xs={12} md={6} className={styles.formContainer}>
                        <Typography variant="h4"><Box fontWeight="bold">Let's Schedule Your <br />Auction Event</Box></Typography>
                        <div className={styles.form}>
                            {form}
                        </div>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6}>
                        <CampaignInfo campaign={campaign} />
                    </Grid>
                </Grid>
            )}
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={serverError != null}
                TransitionComponent={TransitionUp}
                onClose={closeSnackbar}
                key={`snackbar_error`}
                autoHideDuration={7000}
            >
                <Alert
                    severity="error"
                >
                    {serverError}
                </Alert>
            </Snackbar>
        </>
    );
};