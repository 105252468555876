import React, { useState } from 'react';
import { 
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import { addHours } from 'date-fns';
import CancelIcon from '@material-ui/icons/Cancel';
import StarsIcon from '@material-ui/icons/Stars';
import CloudIcon from '@material-ui/icons/Cloud';
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
    checkBoxContainer: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
}));

export const DatePicker = ({title, dates, updateSelectedDates, selectedDates, updatedReason, reason, error, location, maxSelectedDates, headline, rescheduledNumber, isWinner }) => {
    const styles = useStyles();
    const _dates = dates?.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const noneSelected = (selectedDates ?? []).find(d => d.duration <= 0);
    const checkedDate = (date,checked) => {
        if (checked && (selectedDates ?? []).length < maxSelectedDates) {
            updateSelectedDates([...(selectedDates ?? []), date]);
        } else {
            removeSelectedDate(date);
        }
    };

    const removeSelectedDate = (date) => {
        updateSelectedDates((selectedDates ?? []).filter(d => d.id !== date.id));
    };

    const isPreferred = (d) => {
        const sortedDates = (selectedDates ?? []).sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        if (sortedDates.length > 0) {
            if (sortedDates[0].id === d.id) {
                return true;
            }
        }

        return false;
    };

    const isRainDate = (d) => {
        const sortedDates = (selectedDates ?? []).sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        if (sortedDates.length > 1) {
            if (sortedDates[1].id === d.id) {
                return true;
            }
        }

        return false;
    };

    return (
        <TableContainer component={Paper}>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{headline}</Typography>
                        <Typography variant="caption" color="textSecondary">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {((!selectedDates || (selectedDates && selectedDates.length <= 0)) && (!isWinner || rescheduledNumber < 2)) && (
                            <Button variant="outlined" color="primary" size="small" onClick={() => updateSelectedDates([{ id: -1, date: '', duration: -1 }])}>None of these dates work</Button>
                        )}
                        {(rescheduledNumber >= 2 && isWinner) && (
                            <Typography color="primary">You have used the maximum number of reschedules. Please select from the list below or your round will be forfeit.</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Table>
                <TableBody>
                    {noneSelected ? (
                        <TableRow style={{ cursor: 'pointer' }}>
                            <TableCell>
                                <Typography color="primary">Selected None</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    variant="filled"
                                    label="Reason / Alternative Dates"
                                    value={reason}
                                    onChange={(e) => updatedReason(e.target.value)}
                                />
                            </TableCell>
                            <TableCell align="right" onClick={() => removeSelectedDate(noneSelected)}>
                                <CancelIcon />
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {_dates?.map((d,i) => {
                                const dateStr = d.date.replaceAll('-', '/');
                                const date = new Date(`${dateStr} GMT`);
                                const mom = moment(date).tz(location?.timezone ?? 'America/New_York');
                                const momEnd = moment(addHours(date, d.duration)).tz(location?.timezone ?? 'America/New_York');
                                return (
                                    <TableRow key={`pickerdate_${i}`} style={{ cursor: 'pointer' }} onClick={(e) => checkedDate(d, !(selectedDates ?? []).find(sd => sd.id === d.id) > 0)}>
                                        <TableCell size="medium">
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item><EventIcon style={{ color: 'gray' }} /></Grid>
                                                <Grid item>
                                                    <strong>{mom.format('ll')}</strong> {mom.format('h:mm a')} - {momEnd.format('h:mm a z')}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell size="small">
                                            <Grid container className={styles.checkBoxContainer} alignItems="center" spacing={2}>
                                                <Grid item>
                                                    {isPreferred(d) && (
                                                        <Tooltip title="Preferred Date">
                                                            <StarsIcon color="secondary"/>
                                                        </Tooltip>
                                                    )}
                                                    {isRainDate(d) && (
                                                        <Tooltip title="Rain Date">
                                                            <CloudIcon color="secondary"/>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={(selectedDates ?? []).find(sd => sd.id === d.id) !== undefined}
                                                        // onChange={(e) => checkedDate(d, e.target.checked)}

                                                        color="primary"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </>
                    )}
                </TableBody>
            </Table>
            {error && error.length > 0 && (
                <Box p={2}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </TableContainer>
    );
};
export default DatePicker;
