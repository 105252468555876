import React, { useState, useEffect } from 'react';
import { 
    Table,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    TableContainer,
    Paper,
    Box,
    Typography,
    TextField,
    Grid
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const GuestSelectorRow = ({ index, name, email, remove, updateGuest }) => {
    return (
        <TableRow>
            <TableCell size="small">
                <IconButton onClick={() => remove(index)}>
                    <RemoveCircleIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <TextField
                    required
                    fullWidth
                    variant="filled"
                    label="Name"
                    value={name}
                    onChange={(e) => updateGuest(index, e.target.value, email)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    required
                    fullWidth
                    variant="filled"
                    label="Email"
                    value={email}
                    onChange={(e) => updateGuest(index, name, e.target.value)}
                />
            </TableCell>
        </TableRow>
    )
};

export const GuestSelector = ({ title, guestsUpdated, error, max, winnerName, winnerEmail }) => {
    const [guests, setGuests] = useState([]);

    useEffect(() => {
        guestsUpdated(guests);
    }, [guests])

    useEffect(() => {
        if (winnerName || winnerEmail) {
            setGuests([{email: winnerEmail || '', name: winnerName || ''}])
        }
    }, [winnerName, winnerEmail])

    const removeGuest = (i) => {
        if (guests.length > 0) {
            let d = [...guests];
            d.splice(i, 1);
            setGuests(d);
        }
    };

    const addGuest = () => {
        setGuests([...guests, {email: '', name: ''}]);
    };

    const updateGuest = (i, name, email) => {
        const d = [...guests];
        d[i] = { name, email };
        setGuests(d);
    };

    return (
        <TableContainer component={Paper}>
            <Box p={2}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">Guests</Typography>
                        <Typography variant="caption" color="textSecondary">{title}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Table>
                <TableBody>
                    {guests.map((guest,i) => (
                        <GuestSelectorRow 
                            key={`guestrow_${i}`} 
                            index={i}
                            name={guest.name}
                            email={guest.email} 
                            remove={removeGuest} 
                            updateGuest={updateGuest} 
                        />
                    ))}
                    {guests.length < max && (
                        <TableRow style={{ borderTop: '1 solid gray' }}>
                            <TableCell width={40}>
                                <IconButton onClick={addGuest}>
                                    <AddCircleIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {error && error.length > 0 && (
                <Box p={2}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </TableContainer>
    );
};
export default GuestSelector;
