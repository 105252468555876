import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { WinnerCampaignForm } from './WinnerCampaignForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper:  {
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(5),
            height: `calc(100vh - 70px - ${theme.spacing(10)}px)`,
        },
    },
    form: {
        height: '100%'
    }
}));

export const WinnerPage = () => {
    const styles = useStyles();

    return (
        <Paper className={styles.paper}> 
            <Box className={styles.form}>
                <WinnerCampaignForm />
            </Box>
        </Paper>
    );
};
export default WinnerPage;