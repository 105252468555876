import React, { useState, useEffect } from 'react';
import { 
    Table,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    TableContainer,
    Paper,
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Grid
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const DateSelectorRow = ({ index, date, duration, remove, updatedDates }) => {

    const handleUpdateDate = (dat, dur) => {
        updatedDates(index, dat, dur);
    };

    return (
        <TableRow>
            <TableCell style={{ width: 20 }}>
                <IconButton onClick={() => remove(index)} style={{ padding: 0 }}>
                    <RemoveCircleIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <KeyboardDateTimePicker
                    disablePast={true}
                    clearable={false}
                    fullWidth={true}
                    variant="inline"
                    ampm={true}
                    label="Start Date & Time"
                    value={date}
                    onChange={(e) => {
                        handleUpdateDate(e, duration);
                    }}
                    onError={console.log}
                    format="MM/dd/yyyy hh:mma"
                />
            </TableCell>
            <TableCell size="small">
                <FormControl fullWidth>
                    <InputLabel id="duration">Duration</InputLabel>
                    <Select
                        labelId="duration"
                        value={duration}
                        onChange={(e) => {
                            handleUpdateDate(date, e.target.value);
                        }}
                    >
                        <MenuItem value={4}>4hrs</MenuItem>
                        <MenuItem value={5}>5hrs</MenuItem>
                        <MenuItem value={6}>6hrs</MenuItem>
                        <MenuItem value={7}>7hrs</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
        </TableRow>
    )
};

export const DateSelector = ({title, datesUpdated, error, headline }) => {
    const [dates, setDates] = useState([]);

    useEffect(() => {
        datesUpdated(dates);
    }, [dates])

    const removeDate = (i) => {
        if (dates.length > 0) {
            let d = [...dates];
            d.splice(i, 1);
            setDates(d);
        }
    };

    const addDate = () => {
        setDates([...dates, {date: new Date(), duration: 0}]);
    };

    const updatedDates = (i, date, duration) => {
        const d = [...dates];
        d[i] = { date, duration };
        setDates(d);
    };

    return (
        <TableContainer component={Paper}>
            <Box p={2}>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={10}>
                        <Typography variant="h5">{headline}</Typography>
                        <Typography variant="caption" color="textSecondary">{title}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Table>
                <TableBody>
                    {dates.map((date,i) => (
                        <DateSelectorRow 
                            key={`daterow_${i}`} 
                            index={i}
                            date={date.date} 
                            duration={date.duration}
                            remove={removeDate} 
                            updatedDates={updatedDates} 
                        />
                    ))}
                    <TableRow style={{ borderTop: '1 solid gray' }}>
                        <TableCell>
                            <IconButton onClick={addDate} style={{ padding: 0 }} >
                                <AddCircleIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableBody>
            </Table>
            {error && error.length > 0 && (
                <Box p={2}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </TableContainer>
    );
};
export default DateSelector;
