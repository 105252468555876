import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Collapse,
    Paper,
    Switch,
    Box,
    Checkbox,
    Divider,
    Slide,
    Snackbar,
    Fade,
    CircularProgress,
    MenuItem,
    FormControl,
    InputLabel,
    Select, 
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel,
    ButtonGroup,
    InputAdornment,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined'
import Clear from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import { PlacesTextField, DateSelector } from '../../components';
import axios from 'axios';
import { API_URL } from '../../constants';
import { format, addMinutes } from 'date-fns';
import ReactGA from 'react-ga';
import { useHistory, useParams } from 'react-router';
import { AppContext } from '../../contexts';
import { convertDateToTimezone } from '../../helpers/Date';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    toggleButton: {
        borderRadius: '15px !important',
        border: '2px solid rgba(66, 142, 117, .1)',
        borderLeft: '2px solid rgba(66, 142, 117, .1) !important',
        width: '100%',
        '&:hover': {
            backgroundColor: 'rgba(66, 142, 117, .1)'
        }
    },
    toggleButtonSelected: {
        borderRadius: '15px !important',
        border: '2px solid rgba(66, 142, 117, 1)',
        borderLeft: '2px solid rgba(66, 142, 117, 1) !important',
        backgroundColor: 'rgba(66, 142, 117, .1) !important',
        width: '100%',
        '&:hover': {
            backgroundColor: 'rgba(66, 142, 117, .1)'
        }
    },
}));


function formatDate(date) {
    return format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
}

// function changeTimezone(date, ianatz) {
//     // suppose the date is 12:00 UTC
//     var invdate = new Date(date.toLocaleString('en-US', {
//       timeZone: ianatz
//     }));

//     // then invdate will be 07:00 in Toronto
//     // and the diff is 5 hours
//     var diff = date.getTime() - invdate.getTime();

//     // so 12:00 in Toronto is 17:00 UTC
//     return new Date(date.getTime() - diff); // needs to substract
//   }

function convertDatesToTimeZone(dates, timezone) {

}

const initialValues = {
    name: '',
    email: '',
    phone: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    packageDescription: '',
    charityName: '',
    charityAddress: '',
    charityPhone: '',
    clubRelation: '',
    hostASecondRound: false,
    numberOfGuestsAllowed: 0,
    roundsOfGolf: 1,
    lunchDinner: false,
    caddies: false,
    memberOfClub: true,
    clubRules: '',
    termsOfService: false,
    donationType: "round",
    bookingRestrictions: '',
    attributesPaidBy: '',
    cashCost: ''
};

const initialStatus = {
    errors: []
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = () => Yup.object().shape({
    name: Yup.string().required('* Required'),
    email: Yup.string().required('* Required').email('Invalid Email'),
    contactPersonEmail: Yup.string().email('Invalid Email'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    contactPersonPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
       packageDescription: Yup.string().required('* Required'),
    // numberOfGuestsAllowed: Yup.number().required('* Required'),
    // roundsOfGolf: Yup.number().required('* Required'),
    termsOfService: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted."),
    charityPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    charityAddress: Yup.string().max(155, 'Must be less than 155 characters'),
    charityName: Yup.string().max(155, 'Must be less than 155 characters'),
});

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export const HostCampaignForm = () => {
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const [contactPersonOpen, setContactPersonOpen] = useState(false);
    const [dates, setDates] = useState([]);
    const [dateSelectorError, setDateSelectorError] = useState('');
    const [location, setLocation] = useState(undefined);
    const [locationError, setLocationError] = useState(undefined);
    const [serverError, setSeverError] = useState(undefined);
    const [succes, setSuccess] = useState(false);
    const [deferDateSelection, setDeferDateSelection] = useState(false);
    const [loading, setLoading] = useState(false);
    const closeSnackbar = () => setSeverError(undefined);
    const { hash } = useParams();
    const [attributes, setAttributes] = useState([]); 
    const allAttributes = ['Walking Without Caddies', 'Cart', 'Cart w/ Forecaddies', 'Walking Caddies']
    const [addons, setAddons] = useState([])
    const allAddons = ['Lunch', 'Dinner', 'Drinks'] 
    const [newAddons, setNewAddons] = useState()
    const [certificate, setCertificate] = useState(null)
    const styles = useStyles()
    

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        const getData = async () => {
            if (!appCtx.npo) {
                setLoading(true);
                try {
                    const result = await axios.get(`${API_URL}/npo/${hash}`);
                    if (result.data && result.data.response) {
                        appCtx.setNpo(result.data.response);
                    } else {
                        history.push('/notfound');
                    }
                } catch (e) {
                    setLoading(false);
                    history.push('/notfound');
                }
                setLoading(false);
            }
        };
        getData();
    }, []);

    const handleSubmit = async (values, formikHelpers) => {
        setDateSelectorError(undefined);
        setLocationError(undefined);

        if (!location && values.donationType !== 'other') {
            setLocationError('Please select the club location');
            return;
        }

        if (dates && dates.length > 0) {
            for (const date of dates) {
                if (date.duration <= 0) {
                    setDateSelectorError('Please make sure the duration is set for all dates');
                    return;
                }
            }

            if (dates.length < 4) {
                setDateSelectorError('Please select at least 4 dates');
                return;
            }
        }

        try {
            setLoading(true);
            const data = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                numberOfAllowedGuests: values.numberOfGuestsAllowed,
                roundsOfGolf: values.roundsOfGolf,
                includesMeal: Number(values.lunchDinner),
                hostASecondRound: Number(values.hostASecondRound),
                includesCaddies: Number(values.caddies),
                packageDescription: values.packageDescription,
                isClubMember: Number(values.memberOfClub),
                clubRelation: values.clubRelation,
                clubRules: values.clubRules,
                location: JSON.stringify(location),
                dates: JSON.stringify(dates.map(d => ({ date: convertDateToTimezone(d.date, location?.timezone ?? 'America/New_York').format('YYYY-MM-DD HH:mm:ss'), duration: d.duration }))),
                contactPersonName: values.contactPersonName,
                contactPersonPhone: values.contactPersonPhone,
                contactPersonEmail: values.contactPersonEmail,
                npoId: appCtx.npo?.id,
                charityName: values.charityName,
                charityAddress: values.charityAddress,
                charityPhone: values.charityPhone,
                donationType: values.donationType,
                bookingRestrictions: values.bookingRestrictions,
                attributes: attributes,
                attributesPaidBy: values.attributesPaidBy,
                addons: addons,
                certificate: certificate,
                cashCost: values.cashCost
            };
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const result = await axios.post(`${API_URL}/host`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // console.table(data)
            if (result.data.status === 'success') {
                setSuccess(true);
            }
            // console.table(result)
            // console.log(result)
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(`failed to make submission with error: `, e);
            setSeverError(e.message);
        }
    };




    const handleAttributes = (event, newAttributes) => {
        if (attributes.includes(newAttributes)){
            setAttributes(attributes.filter(a => a !== newAttributes))
        } else {
            setAttributes([...attributes, newAttributes]);
        }
    };
    
    const handleAddons = (event, newAddons) => {
        if (newAddons === '' || newAddons === undefined) {
            return
        }
        if (addons.includes(newAddons)){
            setAddons(addons.filter(a => a !== newAddons))
        } else {
            setAddons([...addons, newAddons]);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
          setCertificate(file);
        }
      };



    if (loading) {
        return (
            <Grid container style={{ minHeight: 300 }} justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {succes && (
                <Fade in={succes}>
                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                        <Grid item style={{ textAlign: 'center' }} xs={12} md={10} lg={7}>
                            <CheckCircleIcon fontSize="large" color="primary" />
                            <Typography variant="h5" style={{ marginBottom: 20 }}>Thank you so much for your support & generous donation</Typography>
                            <Typography variant="body1">You'll be hearing from us when the winner of the auction chooses their preferred dates. If you have any questions please feel free to email us at <a href="mailto:info@memberforaday.com">info@memberforaday.com</a></Typography>
                        </Grid>
                    </Grid>
                </Fade>
            )}

            {!succes && (
                <Formik
                    initialValues={initialValues}
                    initialStatus={initialStatus}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                >
                    {formikVar => {
                        return (
                            <form
                                onSubmit={e => formikVar.handleSubmit(e)}
                                style={{color: 'rgba(0, 0, 0, 0.54)'}}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Donor Info</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field validateOnBlur validateOnChange name="name">
                                            {() => (
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    name="name"
                                                    label="Full Name"
                                                    value={formikVar.values.name}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.name && formikVar.touched.name)}
                                                    helperText={formikVar.errors.name && formikVar.touched.name && String(formikVar.errors.name)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field validateOnBlur validateOnChange name="phone">
                                            {() => (
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    name="phone"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    label="Phone"
                                                    value={formikVar.values.phone}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.phone && formikVar.touched.phone)}
                                                    helperText={formikVar.errors.phone && formikVar.touched.phone && String(formikVar.errors.phone)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field validateOnBlur validateOnChange name="email">
                                            {() => (
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="email"
                                                    label="Email"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    value={formikVar.values.email}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.email && formikVar.touched.email)}
                                                    helperText={formikVar.errors.email && formikVar.touched.email && String(formikVar.errors.email)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ backgroundColor: 'rgba(250, 250, 250, 0)' }}>
                                            <Box p={1}>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid item onClick={() => setContactPersonOpen(!contactPersonOpen)} style={{cursor: 'pointer'}}>
                                                        <Typography variant="body1" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                                                            {contactPersonOpen ? 
                                                                <span>- </span>
                                                            :
                                                                <span>+ </span>
                                                            }
                                                            Contact details for scheduling
                                                            <span fontStyle="oblique"> (if different from above)</span>
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <Switch
                                                            checked={contactPersonOpen}
                                                            onChange={(e) => setContactPersonOpen(e.target.checked)}
                                                            color="primary"
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                                <Collapse in={contactPersonOpen} timeout="auto" unmountOnExit fullWidth>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Field validateOnBlur validateOnChange name="contactPersonName">
                                                                {() => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        name="contactPersonName"
                                                                        label="Full Name"
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: "15px",
                                                                            },
                                                                        }}
                                                                        value={formikVar.values.contactPersonName}
                                                                        onChange={formikVar.handleChange}
                                                                        onBlur={formikVar.handleBlur}
                                                                        error={Boolean(formikVar.errors.contactPersonName && formikVar.touched.contactPersonName)}
                                                                        helperText={formikVar.errors.contactPersonName && formikVar.touched.contactPersonName && String(formikVar.errors.contactPersonName)}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Field validateOnBlur validateOnChange name="contactPersonPhone">
                                                                {() => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        name="contactPersonPhone"
                                                                        label="Phone"
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: "15px",
                                                                            },
                                                                        }}
                                                                        values={formikVar.values.contactPersonPhone}
                                                                        onChange={formikVar.handleChange}
                                                                        onBlur={formikVar.handleBlur}
                                                                        error={Boolean(formikVar.errors.contactPersonPhone && formikVar.touched.contactPersonPhone)}
                                                                        helperText={formikVar.errors.contactPersonPhone && formikVar.touched.contactPersonPhone && String(formikVar.errors.contactPersonPhone)}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field validateOnBlur validateOnChange name="contactPersonEmail">
                                                                {() => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        name="contactPersonEmail"
                                                                        label="Email"
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: "15px",
                                                                            },
                                                                        }}
                                                                        value={formikVar.values.contactPersonEmail}
                                                                        onChange={formikVar.handleChange}
                                                                        onBlur={formikVar.handleBlur}
                                                                        error={Boolean(formikVar.errors.contactPersonEmail && formikVar.touched.contactPersonEmail)}
                                                                        helperText={formikVar.errors.contactPersonEmail && formikVar.touched.contactPersonEmail && String(formikVar.errors.contactPersonEmail)}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Collapse>
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Dialog 
                                        open={formikVar.values.donationType === "certificate" && certificate === null} 
                                        onClose={() => {
                                                if (certificate === null) {
                                                    formikVar.setFieldValue('donationType', 'round')
                                                }
                                            }
                                        }>
                                            <DialogTitle>Please Upload Certificate</DialogTitle>
                                            <DialogContent>
                                                <div
                                                    style={{

                                                        border: '2px dashed #ccc',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        margin: 15,
                                                        padding: 15,
                                                        backgroundColor: '#eee',
                                                        width: '300px',
                                                        height: '200px',
                                                        alignContent: 'center'
                                                    }}
                                                    onDrop={handleDrop}
                                                    onDragOver={(event) => event.preventDefault()}
                                                >
                                                    <CloudUploadOutlined fontSize='large' />
                                                    <Typography>Drag and drop your file here</Typography>
                                                </div>
                                                
                                                <input 
                                                    type="file" 
                                                    id="certificate-upload"
                                                    style={{display: 'none'}}
                                                    onChange={(e) => {setCertificate(e.target.files[0])}}
                                                />
                                                <DialogActions>
                                                <label htmlFor="certificate-upload">
                                                    <Button variant="contained" component="span">
                                                        Open File Picker
                                                    </Button>
                                                </label>
                                                </DialogActions>
                                            </DialogContent>
                                    </Dialog>

                                    <Grid item xs={12} style={{paddingTop: '30px', paddingBottom: '0px'}}>
                                        {/* <div style={{display: 'flex', justifyContent: 'center'}}> */}
                                        <div>
                                            {/* <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="round"
                                                name="donationType"
                                                value={formikVar.values.donationType}
                                                onChange={formikVar.handleChange}
                                            >
                                                <FormLabel id="demo-radio-buttons-group-label">Select Type of Donation</FormLabel>
                                                <FormControlLabel value="round" control={<Radio />} label="Round of Golf, hosted by Member" />
                                                <FormControlLabel value="certificate" control={<Radio />} label="Certificate for Round of Golf" />
                                                {certificate !== null ?
                                                    <TextField
                                                            fullWidth
                                                            disabled
                                                            rows={1}
                                                            variant="outlined"
                                                            name={certificate.name}
                                                            // label={certificate.name}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" aria-label="remove" onClick={(e) => {setCertificate(null); formikVar.setFieldValue('donationType', 'round');}}>
                                                                            <Clear />
                                                                        </IconButton>                                                      
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    borderRadius: "15px",
                                                                },
                                                            }}
                                                            value={certificate.name}
                                                        />
                                                :null}
                                                <FormControlLabel value="package" control={<Radio />} label="Stay & Play Package" />
                                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                                            </RadioGroup> */}
                                            <FormLabel>Select Type of Donation</FormLabel>
                                            <ToggleButtonGroup
                                                value={formikVar.values.donationType}
                                                // name="donationType"
                                                // defaultValue="round"
                                                exclusive
                                                onChange={(e, type) => formikVar.setFieldValue('donationType', type)}
                                                // onChange={formikVar.handleChange}
                                                aria-label="multi-select"
                                                style={{paddingTop: '15px', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '10px'}}
                                            >
                                                <ToggleButton name="donationType"
                                                    value='round'
                                                    className={formikVar.values.donationType === 'round' ? styles.toggleButtonSelected : styles.toggleButton}
                                                >
                                                    Hosted Round of Golf
                                                </ToggleButton>
                                                <ToggleButton name="donationType"
                                                    value='certificate'
                                                    className={formikVar.values.donationType === 'certificate' ? styles.toggleButtonSelected : styles.toggleButton}
                                                >
                                                    Certificate for Golf
                                                </ToggleButton>
                                                <ToggleButton name="donationType"
                                                    value='package'
                                                    className={formikVar.values.donationType === 'package' ? styles.toggleButtonSelected : styles.toggleButton}
                                                >
                                                    Stay & Play Package
                                                </ToggleButton>
                                                <ToggleButton name="donationType"
                                                    value='other'
                                                    className={formikVar.values.donationType === 'other' ? styles.toggleButtonSelected : styles.toggleButton}
                                                >
                                                    Other
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            {certificate !== null ?
                                                <TextField
                                                    fullWidth
                                                    disabled
                                                    rows={1}
                                                    variant="outlined"
                                                    name={certificate.name}
                                                    // label={certificate.name}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" aria-label="remove" onClick={(e) => {setCertificate(null); formikVar.setFieldValue('donationType', 'round');}}>
                                                                    <Clear />
                                                                </IconButton>                                                      
                                                            </InputAdornment>
                                                        ),
                                                        style: {
                                                            borderRadius: "15px",
                                                            marginTop: '15px'
                                                        },
                                                    }}
                                                    value={certificate.name}
                                                />
                                            :null}
                                        </div>
                                    </Grid>

                                    

                                {formikVar.values.donationType !== 'other' ? <>
                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Course</FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PlacesTextField
                                            name="club"
                                            label="Name of Golf Course"
                                            variant="outlined"
                                            InputProps={{
                                                style: {
                                                    borderRadius: "0px",
                                                },
                                            }}
                                            selectedLocation={(l) => {
                                                setLocation(l);
                                                setLocationError(undefined);
                                            }}
                                            error={locationError}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel id="toggleButton-buttons-group-label">Round Attributes</FormLabel>
                                        <br></br>
                                        <FormLabel>(Select multiple if up to winner)</FormLabel>
                                        {/* <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap'}}> */}
                                        <div style={{paddingTop: '20px'}}>
                                        
                                            <ToggleButtonGroup
                                                value={attributes}
                                                exclusive
                                                onChange={handleAttributes}
                                                aria-label="multi-select"
                                                style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px'}}
                                            >
                                                {allAttributes.map(a => (
                                                    <ToggleButton 
                                                        value={a}
                                                        className={attributes.includes(a) ? styles.toggleButtonSelected : styles.toggleButton}
                                                    >
                                                        {a}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {attributes.length > 0 ? 
                                            <div style={{paddingTop: '15px'}}>
                                                <FormLabel id="attributesPaidBy-radio-buttons-group-label">Paid for by:</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="attributesPaidBy-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="attributesPaidBy"
                                                    value={formikVar.values.attributesPaidBy}
                                                    onChange={formikVar.handleChange}
                                                >
                                                    <FormControlLabel value="donor" control={<Radio />} label="Donor" />
                                                    <FormControlLabel value="winner" control={<Radio />} label="Winner" />
                                                </RadioGroup>
                                            </div>
                                        :null}
                                    </Grid>

                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Select # Guests and Rounds</FormLabel>
                                    </Grid>
                                    
                                    <Grid item xs={6}>
                                        <Field validateOnBlur validateOnChange name="numberOfGuestsAllowed">
                                            {() => (
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    name="numberOfGuestsAllowed"
                                                    label="Number of Guests Allowed"
                                                    value={formikVar.values.numberOfGuestsAllowed}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.numberOfGuestsAllowed && formikVar.touched.numberOfGuestsAllowed)}
                                                    helperText={formikVar.errors.numberOfGuestsAllowed && formikVar.touched.numberOfGuestsAllowed && String(formikVar.errors.numberOfGuestsAllowed)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field validateOnBlur validateOnChange name="roundsOfGolf">
                                            {() => (
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    name="roundsOfGolf"
                                                    label="Rounds of Golf"
                                                    value={formikVar.values.roundsOfGolf}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.roundsOfGolf && formikVar.touched.roundsOfGolf)}
                                                    helperText={formikVar.errors.roundsOfGolf && formikVar.touched.roundsOfGolf && String(formikVar.errors.roundsOfGolf)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel id="toggleButton-buttons-group-label">Add-ons to this donation</FormLabel>
                                        {/* <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap'}}> */}
                                        <div >
                                            <Grid item xs={12} style={{paddingTop: "20px"}}>
                                                <ToggleButtonGroup
                                                    value={addons}
                                                    exclusive
                                                    onChange={handleAddons}
                                                    aria-label="multi-select"
                                                    style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px'}}
                                                >
                                                    {allAddons.map(a => (
                                                        <ToggleButton 
                                                            value={a}
                                                            className={addons.includes(a) ? styles.toggleButtonSelected : styles.toggleButton}
                                                        >
                                                            {a}
                                                        </ToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {addons.filter(a => !allAddons.includes(a)).map((a) => (
                                                    <div style={{paddingTop: '15px'}}>
                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            rows={1}
                                                            variant="outlined"
                                                            name={a}
                                                            label={a}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" aria-label="add" onClick={(e) => {handleAddons(e, a); setNewAddons('');}}>
                                                                            <Clear />
                                                                        </IconButton>                                                      
                                                                    </InputAdornment>
                                                                ),
                                                                style: {
                                                                    borderRadius: "15px",
                                                                },
                                                            }}
                                                            value={a}
                                                        />
                                                    </div>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12} style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                                <TextField
                                                    fullWidth
                                                    rows={1}
                                                    variant="outlined"
                                                    name="additionalItems"
                                                    label="Additional Items"
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                          console.log('wutwutwutwut')
                                                          e.preventDefault();
                                                          handleAddons(e, newAddons)
                                                          setNewAddons('')
                                                        }
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" aria-label="add" onClick={(e) => {handleAddons(e, newAddons); setNewAddons('');}}>
                                                                    <Add />
                                                                </IconButton>                                                      
                                                            </InputAdornment>
                                                        ),
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    value={newAddons}
                                                    onChange={(e) => setNewAddons(e.target.value)}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </> :null}
                                    

                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <Typography>Cash Cost to Procure Donation</Typography>
                                        <Typography style={{paddingBottom: '15px'}}>ie.guest fees, food / drink est. if included, etc</Typography>
                                        <Field validateOnBlur validateOnChange name="cashCost">
                                            {() => (
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    number
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: '$',
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    name="cashCost"
                                                    label="Cash Cost to Procure Donation"
                                                    value={formikVar.values.cashCost}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.cashCost && formikVar.touched.cashCost)}
                                                    helperText={formikVar.errors.cashCost && formikVar.touched.cashCost && String(formikVar.errors.cashCost)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Description</FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field validateOnBlur validateOnChange name="packageDescription">
                                            {() => (
                                                <TextField
                                                    required
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    name="packageDescription"
                                                    label="Package Description"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    value={formikVar.values.packageDescription}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.packageDescription && formikVar.touched.packageDescription)}
                                                    helperText={formikVar.errors.packageDescription && formikVar.touched.packageDescription && String(formikVar.errors.packageDescription)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                {formikVar.values.donationType !== 'other' ? <>
                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Booking Restrictions</FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field validateOnBlur validateOnChange name="bookingRestrictions">
                                            {() => (
                                                <TextField
                                                    // required
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    variant="outlined"
                                                    name="bookingRestrictions"
                                                    label="Our standard terms are a mutually agreed upon date in the next 12 months. If this item should have other terms, let us know."
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    value={formikVar.values.bookingRestrictions}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.bookingRestrictions && formikVar.touched.bookingRestrictions)}
                                                    helperText={formikVar.errors.bookingRestrictions && formikVar.touched.bookingRestrictions && String(formikVar.errors.bookingRestrictions)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>


                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <FormLabel>Club Rules</FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field validateOnBlur validateOnChange name="clubRules">
                                            {() => (
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    name="clubRules"
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "15px",
                                                        },
                                                    }}
                                                    label="Any Club Rules your guest should be aware of?"
                                                    values={formikVar.values.clubRules}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.clubRules && formikVar.touched.clubRules)}
                                                    helperText={formikVar.errors.clubRules && formikVar.touched.clubRules && String(formikVar.errors.clubRules)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>


                                    <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Would you be willing to host a second round at the price of the winning bid?</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field validateOnBlur validateOnChange name="hostASecondRound">
                                                    {() => (
                                                        <Checkbox
                                                            name="hostASecondRound"
                                                            value={formikVar.values.hostASecondRound}
                                                            onChange={formikVar.handleChange}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>


                                    {/* <Grid item xs={12} style={{paddingTop: '30px'}}>
                                        <Divider />
                                    </Grid>
                                    {!deferDateSelection && (
                                        <Grid item xs={12}>
                                            <DateSelector
                                                datesUpdated={(d) => setDates(d)}
                                                error={dateSelectorError}
                                                headline="Tee Times"
                                                title="Please add some dates that would work for you. Dates should be selected in the same timezone as the course location."
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Defer to your guest’s availability</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Checkbox
                                                    value={deferDateSelection}
                                                    onChange={(e) => {
                                                        setDeferDateSelection(e.target.checked);
                                                        if (e.target.checked) {
                                                            setDates([]);
                                                        }
                                                    }}
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid> */}

                                    


                                   

                                    

                                    {/* <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Includes caddies</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field validateOnBlur validateOnChange name="caddies">
                                                    {() => (
                                                        <Checkbox
                                                            name="caddies"
                                                            value={formikVar.values.caddies}
                                                            onChange={formikVar.handleChange}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Includes lunch/dinner</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field validateOnBlur validateOnChange name="lunchDinner">
                                                    {() => (
                                                        <Checkbox
                                                            name="lunchDinner"
                                                            value={formikVar.values.lunchDinner}
                                                            onChange={formikVar.handleChange}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    {/* <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Club Relation</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field validateOnBlur validateOnChange name="memberOfClub">
                                                    {() => (
                                                        <Checkbox
                                                            name="memberOfClub"
                                                            value={formikVar.values.memberOfClub}
                                                            onChange={formikVar.handleChange}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    {formikVar.values.memberOfClub && (
                                        <Grid item xs={12}>
                                            <Field validateOnBlur validateOnChange name="clubRelation">
                                                {() => (
                                                    <FormControl fullWidth>
                                                        <InputLabel id="clubRelationLabel">Club Relation</InputLabel>
                                                        <Select
                                                            // variant='outlined'
                                                            name="clubRelation"
                                                            labelId="clubRelationLabel"
                                                            value={formikVar.values.clubRelation}
                                                            onChange={formikVar.handleChange}
                                                            onBlur={formikVar.handleBlur}
                                                            error={Boolean(formikVar.errors.clubRelation && formikVar.touched.clubRelation)}
                                                            helperText={formikVar.errors.clubRelation && formikVar.touched.clubRelation && String(formikVar.errors.clubRelation)}
                                                        >
                                                            <MenuItem value="I am a member of the golf club">I am a member of the golf club</MenuItem>
                                                            <MenuItem value="I am staff at the golf club">I am staff at the golf club</MenuItem>
                                                            <MenuItem value="I am not affiliated with the club">I am not affiliated with the club</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>
                                    )}
                                    
                                    {/* <Grid item xs={12}>
                                        <Divider />
                                    </Grid> */}
                                </>: null}    

                                    <Grid item xs={12}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1">Do you agree to the <a color="primary" target="_blank" href="https://www.memberforaday.com/terms-of-service">Terms of Service</a>?</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Checkbox
                                                    required
                                                    name="termsOfService"
                                                    value={formikVar.values.termsOfService}
                                                    onChange={formikVar.handleChange}
                                                    onBlur={formikVar.handleBlur}
                                                    error={Boolean(formikVar.errors.termsOfService && formikVar.touched.termsOfService)}
                                                    helperText={formikVar.errors.termsOfService && formikVar.touched.termsOfService && String(formikVar.errors.termsOfService)}
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={serverError != null}
                TransitionComponent={TransitionUp}
                onClose={closeSnackbar}
                key={`snackbar_error`}
                autoHideDuration={7000}
            >
                <Alert
                    severity="error"
                >
                    {serverError}
                </Alert>
            </Snackbar>
        </>
    );
};
