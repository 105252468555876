import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { DateSelectionForm } from './DateSelectionForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        backgroundImage: 'url("https://i.pinimg.com/originals/d8/05/ee/d805ee8ea6e82d86dd6fd1cbf779bfdc.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: 'calc(100vh - 70px)',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(5)
        },
    },
    form:  {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3)
        },
    }
}));

export const DateSelectionPage = () => {
    const styles = useStyles();

    return (
        <Box className={styles.root}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={11} sm={11} md={11} lg={9} xl={7}>
                    <Paper> 
                        <Box className={styles.form}>
                            <DateSelectionForm />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};
export default DateSelectionPage;