import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export const NotFound = () => {
    return (
        <Grid container justify="center" alignItems="center" style={{ minHeight: 'calc(100vh - 75px)' }}>
            <Grid item>
                <Typography variant="h4">Oops, page not found</Typography>
            </Grid>
        </Grid>
    );
};
export default NotFound;