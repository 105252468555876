import React, { useEffect, useState } from 'react';
import { 
    Grid, 
    Box, 
    Paper, 
    CircularProgress,
    Fade,
    Typography,
    Snackbar,
    Slide,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '../../hooks';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../constants';
import axios from 'axios';

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        backgroundImage: 'url("https://i.pinimg.com/originals/d8/05/ee/d805ee8ea6e82d86dd6fd1cbf779bfdc.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: 'calc(100vh - 70px)',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(5)
        },
    },
    form:  {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3)
        },
    }
}));

export const CancelPage = () => {
    const styles = useStyles();
    const { hash } = useParams();
    const query = useQuery();
    const role = query.get('role'); 
    const [loading, setLoading] = useState(false);
    const [succes, setSuccess] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [serverError, setSeverError] = useState(undefined);
    const [reject, setReject] = useState(false);
    const closeSnackbar = () => setSeverError(undefined);

    useEffect(() => {
        console.log(`called: ${hash} - ${role}`);
        if(confirmCancel) {
            if (hash && hash.length > 0 && role && role.length > 0) {
                const request = async () => {
                    setLoading(true);
                    try {
                        const result = await axios.post(`${API_URL}/cancel/${hash}`, { role });
                        if (result.data.status === 'success') {
                            setSuccess(true);
                        }
                    } catch (e) {
                        setLoading(false);
                        console.log(`failed to make submission with error: `, e);
                        setSeverError(e.message);
                    }
    
                    setLoading(false);
                };
                request();
            }
        }
    }, [hash, role, confirmCancel]);

    return (
        <Box className={styles.root}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={7} lg={6} xl={5}>
                    <Paper> 
                        <Box className={styles.form}>
                            {succes && (
                                <Fade in={succes}>
                                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <CheckCircleIcon fontSize="large" color="primary" />
                                            <Typography variant="h6">Cancellation Requested</Typography>
                                            <Typography variant="h6">You'll be hearing from us very soon. </Typography>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            )}
                            {loading && (
                                <Fade in={loading}>
                                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <CircularProgress color="primary" />
                                        </Grid>
                                    </Grid>
                                </Fade>
                            )}
                            {!confirmCancel && !reject &&(
                                <Fade in={true}>
                                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <Typography variant="h6">Are you sure you want to cancel?</Typography>
                                            <Button style={{margin: 5}} variant='outlined' color="success" onClick={() => setConfirmCancel(true)}>Yes</Button>
                                            <Button style={{margin: 5}} variant='outlined' color="error" onClick={() => setReject(true)}>No</Button>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            )}
                            {reject && (
                                <Fade in={true}>
                                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <Typography variant="h6">We'll see you on the green!</Typography>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            )}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={serverError != null}
                TransitionComponent={TransitionUp}
                onClose={closeSnackbar}
                key={`snackbar_error`}
                autoHideDuration={7000}
            >
                <Alert
                    severity="error"
                >
                    {serverError}
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default CancelPage;