import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../contexts';

const useStyles = makeStyles({
    header: {
        backgroundColor: 'white', 
        borderBottom: '1px solid rgba(230,230,230,1)',
        position: 'fixed', 
        top: 0,
        left: 0,
        width: '100%',
        height: 70,
        zIndex: 100
    },
    logo: {
        height: 60
    }
});

const staticImageURL = 'https://static.wixstatic.com/media/75a547_a00c4c8be42f4dc0add8860682d1ff86~mv2.png/v1/crop/x_0,y_13,w_1650,h_717/fill/w_248,h_108,al_c,q_85,usm_0.66_1.00_0.01/memberforaday-logo_HQ_transparent-paddin.webp';

export const Header = () => {
    const styles = useStyles();
    const appCtx = useContext(AppContext);

    return (
        <Grid container justify="center" alignItems="center" className={styles.header}>
            <Grid item>
                <img 
                    className={styles.logo} 
                    src={appCtx.npo?.image?.length ? appCtx.npo.image : staticImageURL}
                />
            </Grid>
        </Grid>
    );
};

export default Header;