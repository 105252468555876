import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';
import { 
    Grid, 
    Typography,
    Box,
    Button
} from '@material-ui/core';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

const AnyReactComponent = ({ text }) => (
    <Grid container justify="center">
        <Grid item><RoomIcon fontSize="large" style={{ color: '#B00020' }} /></Grid>
        <Grid item><Typography style={{ width: 90 }} variant="body1"><Box fontWeight="bold">{text}</Box></Typography></Grid>
    </Grid>
);

export default class Map extends Component {
    static defaultProps = {
      center: {
        lat: 40.74864367844352,
        lng: -73.98566440153797
      },
      zoom: 11
    };
   
    render() {
        return ( 
            <div style={{ height: '100%', width: '100%', position: 'relative', overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 'calc(100% - 90px)' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAsqe9jWU2wbtW2OC6PWoszdkfO6lHCP6I', zoomControl: false }}
                        defaultCenter={
                            this.props.location ? ({
                                lat: Number(this.props.location.lat),
                                lng: Number(this.props.location.long)
                            }) : this.props.center
                        }
                        defaultZoom={this.props.zoom}
                        options={{
                            fullscreenControl: false,
                            zoomControl: false
                        }}
                    >
                        {this.props?.location && (
                            <AnyReactComponent
                                lat={this.props.location?.lat}
                                lng={this.props.location?.long}
                                text={this.props.location?.name}
                            />
                        )}
                    </GoogleMapReact>
                </div>

                <Grid 
                    container 
                    justify="space-between"
                    alignItems="center"
                    style={{ 
                        position: 'absolute', 
                        bottom: 0, left: 0, 
                        height: 90, 
                        backgroundColor: 'white', 
                        paddingLeft: 15,
                        paddingRight: 15,
                        boxShadow: '5px 3px 5px 6px rgba(0,0,0,0.4)'
                    }}
                >
                    <Grid item xs={10}>
                        <Typography variant="h6">{this.props.location?.name}</Typography>
                        <Typography variant="subtitle1">{this.props.location.address}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button color="primary" onClick={() => window.open(`https://maps.google.com/?q=${this.props.location.address}`, '_blank')}>
                            <OpenInNewRoundedIcon />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}