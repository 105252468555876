import React, { useState } from 'react';

export const AppContext = React.createContext({
    npo: undefined
});

export const AppProvider = ({ children }) => {
  const [npo, setNpo] = useState(undefined);

  // console.log(`npo value: `, npo);

  return (
    <AppContext.Provider value={{ npo, setNpo }}>
      {children}
    </AppContext.Provider>
  );
};
