import React, { useState, useEffect, useContext } from 'react';
import { 
    Grid, 
    Typography, 
    Button,
    Slide,
    Snackbar,
    Fade,
    CircularProgress,
    Paper,
    Box,
    TextField
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Alert } from '@material-ui/lab';
import { DateSelector, DatePicker } from '../../components';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useParams, useHistory } from 'react-router';
import { useQuery } from '../../hooks';
import { format, addMinutes } from 'date-fns';
import ReactGA from 'react-ga';
import { AppContext } from '../../contexts';
import { convertDateToTimezone } from '../../helpers/Date';
import { FullStoryAPI } from 'react-fullstory';

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function formatDate(date) {
    return format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
}

export const DateSelectionForm = () => {
    const appCtx = useContext(AppContext);
    const history = useHistory();
    const { hash } = useParams();
    const query = useQuery();
    const [dates, setDates] = useState([]);
    const [dateSelectorError, setDateSelectorError] = useState('');
    const [datePickerError, setDatePickerError] = useState('');
    const [serverError, setSeverError] = useState(undefined);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState(undefined);
    const [selectedDates, setSelectedDates] = useState(undefined);
    const [selectedDateReason, setSelectedDateReason] = useState('');
    const closeSnackbar = () => setSeverError(undefined);
    const [winnerId, setWinnerId] = useState(query.get('winnerId'));
    const [hostId, setHostId] = useState(query.get('hostId'));
    const [start, setStart] = useState(query.get('start'));
    const step = query.get('step');
    const [savedDates, setSavedDates] = useState(false);
    const [identify, setIdentify] = useState(false);
    const [datesExist, setDatesExist] = useState(false); 
    const winnerParam = query.get('winnerId');
    const hostParam = query.get('hostId');
    const [exceededReschedule, setExceededReschedule] = useState(false)
    const [notes, setNotes] = useState('')

    useEffect(() => {
        if ((winnerId || hostId) && !identify) {
            const userId = winnerId ? winnerId : hostId
            const name = winnerId ? campaign?.winners[0]?.name : campaign?.hosts[0]?.name
            const email = winnerId ? campaign?.winners[0]?.email : campaign?.hosts[0]?.email
            FullStoryAPI('identify', userId, {
                displayName: name,
                email: email
            });
            setIdentify(true)
        }
    }, [winnerId, hostId])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (campaign) {
            if (campaign.winner_dates !== undefined && campaign.winner_dates.length > 0 && !winnerParam) {
                setHostId(campaign.hosts[0].id)
            }
            if (campaign.host_dates !== undefined && campaign.host_dates.length > 0 && !hostParam) {
                setWinnerId(campaign.winners[0].id)
            }
        }
    }, [campaign])

    useEffect(() => {
        if (campaign) {
            if (campaign.winner_dates !== undefined && campaign.winner_dates.length > 0) {
                campaign.winner_dates.map((d) => {
                    if (d.isSelected === 1 || d.isFinalDate === 1) {
                        setSavedDates(true)
                    }
                })
                if (winnerId && winnerId !== undefined) {
                    setSavedDates(true)
                }
            }
            if (campaign.host_dates !== undefined && campaign.host_dates.length > 0) {
                campaign.host_dates.map((d) => {
                    if (d.isSelected === 1 || d.isFinalDate === 1) {
                        setSavedDates(true)
                    }
                })
                if (hostId && hostId !== undefined) {
                    setSavedDates(true)
                }
            }
        }
    }, [campaign])


    useEffect(() => {
        const getData = async () => {
            if (!campaign || success) {
                setLoading(true);
                const result = await axios.get(`${API_URL}/campaign/${hash}`);
                if (result.data && result.data.response) {
                    setCampaign(result.data.response);

                    if (result.data.response.npo) {
                        appCtx.setNpo(result.data.response.npo);
                    }

                    setLoading(false);
                } else {
                    history.push('/notfound');   
                }
            }
        };
        getData();
    }, [success]);

    // useEffect(() => {
    //     if (campaign) {
    //         if(start === null) {
    //             if(!(campaign.winners[0]?.dates.length > 0) && hostId !== null) {
    //                 setStart('true')
    //             }
    //         }
    //     }
    // }, [campaign])

    useEffect(() => {
        if (campaign) {
                if ((campaign.winner_dates && campaign.winner_dates.length > 0) || (campaign.host_dates && campaign.host_dates.length > 0)) {
                    setDatesExist(true)
                }
        }
    }, [campaign])

    useEffect(() => {
        if (selectedDates && selectedDates[0]?.id === -1 && campaign.rescheduled >= 2) {
            setExceededReschedule(true)
        }
    }, [selectedDates, campaign])

    const handleSubmit = async () => {
        setDateSelectorError('');
        setDatePickerError('');

        if (start === 'true' || !datesExist) {
            let dateError = '';
            if (dates.length < 3) {
                dateError = 'Please add at least 3 dates';
            } else {
                for (const date of dates) {
                    if (date.duration <= 1) {
                        dateError = 'Please add a duration to every date';
                    }
                }
            }

            if (dateError.length > 0) {
                setDateSelectorError(dateError);
                return;
            } 
        } else if (!selectedDates) {
            setDatePickerError(`Please select a date that you'll be available on`);
            return;
        }

        setLoading(true);

        try {
            const data = {
                start: start === 'true' ? 1 : 0,
                step: step === '1' ? 1 : 0,
                winnerId: winnerId,
                hostId: hostId,
                notes: notes,
                selectedDates: selectedDates,
                selectedDateReason: selectedDateReason,
                dates: dates.map(d => ({ date: convertDateToTimezone(d.date, campaign.location?.timezone ?? 'America/New_York').format('YYYY-MM-DD HH:mm:ss'), duration: d.duration })),
            };
            const result = await axios.post(`${API_URL}/dateselection/${hash}`, data);

            if (result.data.status === 'success') {
                setSuccess(true);
            }
        } catch (e) {
            setLoading(false);
            console.error(`failed to make submission with error: `, e);
            setSeverError(e.message);
        }

        setLoading(false);
    };

    if (loading) {
        return (
            <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {success && (
                <Fade in={success}>
                    <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                        <Grid item style={{ textAlign: 'center' }}>
                            <CheckCircleIcon fontSize="large" color="primary" />
                            <Typography variant="h6">Success</Typography>
                        </Grid>
                    </Grid>
                </Fade>
            )}
            {savedDates && !success && start !== 'true' && (campaign && campaign.rescheduled <= 2) && (
                <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Typography variant="h6">
                            Sorry, your dates have already been submitted and we are waiting to hear back from your {winnerId ? 'host' : 'winner'}. If you would like to change your date selection please reach out to scheduling@memberforaday.com
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {/* {(!success && winnerParam !== undefined && winnerParam !== null && winnerParam !== "" && (campaign && campaign.rescheduled >= 2)) && (
                <Grid container justify="center" alignItems="center" style={{ minHeight: 300 }}>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Typography variant="h6">
                            Sorry, you have exceeded the maximum number of reschedules. If you feel this is in error, please reach out to scheduling@memberforaday.com.
                        </Typography>
                    </Grid>
                </Grid>
            )} */}
            {((!success && campaign && !savedDates) || (!success && campaign && (start === 'true' || !datesExist))) && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper style={{ backgroundColor: 'rgba(240,240,240,1)' }}>
                            <Box p={2}>
                                <Typography variant="h5" style={{ marginBottom: 15 }}>Event Info</Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}><strong>Includes Meal:</strong> {campaign.includesMeal == 1 ? 'yes' : 'no'}</Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}><strong>Includes Caddies:</strong> {campaign.includesCaddies == 1 ? 'yes' : 'no'}</Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}><strong>Rounds of Golf:</strong> {campaign.roundsOfGolf}</Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}><strong>Guests Allowed:</strong> {campaign.numberOfAllowedGuests}</Typography>
                                <Typography variant="body1"><strong>Club Rules:</strong></Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}>{campaign.clubRules}</Typography>
                                <Typography variant="body1"><strong>Event Description:</strong></Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}>{campaign.packageDescription}</Typography>
                                <Typography variant="body1"><strong>Location:</strong></Typography>
                                <Typography variant="body1">{campaign.location.name}</Typography>
                                <Typography variant="body1" style={{ marginBottom: 8 }}>{campaign.location.address}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {(start === 'true' || !datesExist) && (
                            <DateSelector 
                                datesUpdated={(d) => setDates(d)} 
                                error={dateSelectorError} 
                                headline="Tee Times"
                                title="Please add some dates that would work for you. Dates should be selected in the same timezone as the course location."
                            />
                        )}
                        {(start !== 'true' && datesExist) && (
                            <DatePicker 
                                headline="Tee Times"
                                title={"Please select from the list of available dates supplied below"}
                                dates={winnerId && winnerId !== undefined ? campaign.hosts[0]?.dates : campaign.winners[0]?.dates}
                                location={campaign.location}
                                maxSelectedDates={2}
                                updateSelectedDates={(d) => {
                                    setSelectedDates(d);
                                    setDatePickerError('');
                                    setSelectedDateReason('');
                                }}
                                selectedDates={selectedDates}
                                updatedReason={setSelectedDateReason}
                                reason={selectedDateReason}
                                error={datePickerError}
                                rescheduledNumber={campaign.rescheduled}
                                isWinner={(winnerId !== undefined && winnerId !== "" && winnerId !== null)}
                            />
                        )}
                    </Grid>
                    {selectedDates && selectedDates[0]?.id !== -1 ? (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                rowsMax={6}
                                variant="filled"
                                name="notes"
                                label="Notes"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </Grid>
                    ): null}
           

                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={serverError != null}
                TransitionComponent={TransitionUp}
                onClose={closeSnackbar}
                key={`snackbar_error`}
                autoHideDuration={7000}
            >
                <Alert
                    severity="error"
                >
                    {serverError}
                </Alert>
            </Snackbar>
        </>
    );
};
export default DateSelectionForm;